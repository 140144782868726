import { inject, observer } from "mobx-react";
import React, { useMemo, useEffect, useState } from "react";
import BroadcastStore from "../../_stores/broadcast/BroadcastStore";
import "./style.scss";
import WebRTCPlayer from "common/broadcast/webrtc-player";
import BroadcastStreamStore from "common/broadcast/_stores/broadcast-stream/BroadcastStreamStore";
import { WebRTCStatus } from "common/broadcast/_stores/broadcast/enums";
import TailwindBox from "library/components/_tailwind/box";
import TailwindIcon from "library/components/_tailwind/icon";

interface Props {
  broadcastStore?: BroadcastStore;
  broadcastStreamStore?: BroadcastStreamStore;
}

const BroadcastMemberVerifyViewer: React.FunctionComponent<Props> = ({
  broadcastStore,
}) => {
  const { setWebRTCStatus } = broadcastStore!;
  const [showRefresh, setShowRefresh] = useState(false);
  const [showPlayer, setShowPlayer] = useState(true);

  const handleRefresh = () => {
    setShowPlayer(false);
    setTimeout(() => {
      setShowPlayer(true);
    });
  };

  // use c2c until we broadcast socket sends a show start for verify
  const c2cList = useMemo(() => {
    return Object.values(broadcastStore?.c2cHash || {});
  }, [broadcastStore?.c2cHash]);

  useEffect(() => {
    if (c2cList.length === 0) setWebRTCStatus(WebRTCStatus.Stopped);
  }, [c2cList]);

  if (c2cList.length === 0) {
    return null;
  }

  return (
    <TailwindBox
      className={["BroadcastMemberVerifyViewer"]}
      width='w-full'
      onMouseEnter={() => setShowRefresh(true)}
      onMouseLeave={() => setShowRefresh(false)}
      position={"relative"}>
      {showRefresh && (
        <TailwindIcon
          name='refresh'
          onClick={handleRefresh}
          position='absolute'
          zIndex='z-100'
          textColor='text-white'
          fontSize='text-3xl'
          cursor='cursor-pointer'
          style={{
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
      )}
      {showPlayer && (
        <WebRTCPlayer
          key={`${c2cList[0].memberId}`}
          c2c={c2cList[0]}
          style={{
            width: "100%",
            height: "auto",
          }}
        />
      )}
    </TailwindBox>
  );
};

export default inject(
  "broadcastStore",
  "broadcastStreamStore",
  "profileStore"
)(observer(BroadcastMemberVerifyViewer));
