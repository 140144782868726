import WebrtcViewer from "./c2c-webRTC-viewer";
import React, { useEffect, useRef, useState } from "react";
import BroadcastStreamStore from "../_stores/broadcast-stream/BroadcastStreamStore";
import { IC2C } from "../_stores/broadcast/interfaces";
import { inject, observer } from "mobx-react";
import { IShowType, WebRTCStatus } from "../_stores/broadcast/enums";
import BroadcastStore from "../_stores/broadcast/BroadcastStore";
import { WEBRTC_DROPPED_FRAMES_MAX_RETRY_ATTEMPTS } from "./consts";

export type WebRTCSetup = {
  origin?: string;
  applicationName?: string;
  streamName?: string;
  channelName?: string;
  token?: string;
};

export type WebRTCOnError = {
  error: any;
  code: number;
};
const STREAMSRC = "streamray.com";

type Props = {
  c2c: IC2C;
  broadcastStreamStore?: BroadcastStreamStore;
  broadcastStore?: BroadcastStore;
  style?: React.CSSProperties;
};

const WebRTCPlayer: React.FC<Props> = ({
  broadcastStreamStore,
  broadcastStore,
  c2c,
  style = {},
}) => {
  const { getCam2camStreamCredentials } = broadcastStreamStore!;
  const { setWebRTCStatus, webRTCStatus, currentShowType } = broadcastStore!;
  const viewerRef = useRef<HTMLVideoElement>(null);
  const [muted, setMuted] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  const getWebRTCSetup = (setup: WebRTCSetup) => {
    if (
      setup.origin &&
      setup.applicationName &&
      setup.channelName &&
      setup.token &&
      setup.streamName
    ) {
      const { origin, applicationName, channelName, streamName, token } = setup;
      const dashOrigin = origin.replace(/\./g, "-");
      return {
        applicationName: `${applicationName}/${channelName}`,
        streamName: `${streamName}?token=${token}`,
        wssUrl: `wss://h${dashOrigin}.${STREAMSRC}/webrtc-session.json`,
      };
    }
  };

  const getDynamicStyles = () => {
    return {
      visibility: webRTCStatus === WebRTCStatus.Playing ? "visible" : "hidden",
      height: webRTCStatus === WebRTCStatus.Playing ? "120px" : "0",
      margin: webRTCStatus === WebRTCStatus.Playing ? "10px" : "0",
    };
  };

  useEffect(() => {
    if (!c2c.memberName) return;
    let viewer;
    let dropped_frames_retry_attempt = 0;
    let errorWatcher: number | undefined;

    (async () => {
      const credentials = await getCam2camStreamCredentials(c2c.memberName);
      if (
        !credentials ||
        !credentials.origin ||
        !credentials.applicationName ||
        !credentials.token
      ) {
        console.log(
          "WebRTC Player init error, could not fetch credentials, retries failed"
        );
        return;
      }
      const _config = {
        videoEl: viewerRef.current,
        setup: getWebRTCSetup(credentials),
        onClose: () => {},
        onError: (e: WebRTCOnError) => {
          console.log(e);
        },
        onVideoError: () => {
          if (
            dropped_frames_retry_attempt <
            WEBRTC_DROPPED_FRAMES_MAX_RETRY_ATTEMPTS
          ) {
            dropped_frames_retry_attempt++;
            viewer.stop();
            setTimeout(() => {
              viewer = new WebrtcViewer(_config);
            });
          }
        },
        alwaysPlayTrack: currentShowType === IShowType.VERIFIER,
      };

      const watchForErrors = () => {
        clearTimeout(errorWatcher);
        setTimeout(() => {
          if (
            viewerRef.current?.readyState &&
            viewerRef.current?.readyState > 2 &&
            !viewerRef.current?.paused
          ) {
            console.log("WebRTC Player video is playing and has enough data");
          } else if (!viewerRef.current) {
            console.log("WebRTC Player stopped, ending error watcher");
          } else {
            console.log(
              "WebRTC Player video is not playing, attempting to restart"
            );
            viewer.stop();
            errorWatcher = setTimeout(() => {
              viewer = new WebrtcViewer(_config);
              watchForErrors();
            });
          }
        }, 5000);
      };

      // The 'c2c_show_start' socket event doesn't account for the time it takes for the stream to start
      // Thus, the setTimeout.  webRTCViewer class will also retry if the stream doesn't start
      setTimeout(() => {
        try {
          viewer = new WebrtcViewer(_config);
          watchForErrors();
        } catch (e) {
          console.log("WebRTC Player init error", e);
        }
      }, 500);
    })();

    return () => {
      if (viewer) {
        clearTimeout(errorWatcher);
        viewer.stop();
        console.log("WebRTC Player stopping");
      }
    };
  }, [c2c, isPlaying]);

  useEffect(() => {
    const videoElement = viewerRef.current;
    // if (!videoElement || webRTCStatus === WebRTCStatus.Stopped) return;
    if (!videoElement) return;

    const handlePlay = () => {
      setIsPlaying(true);
    };

    const handlePause = () => {
      setIsPlaying(false);
    };

    videoElement.addEventListener("play", handlePlay);
    videoElement.addEventListener("pause", handlePause);

    return () => {
      videoElement.removeEventListener("play", handlePlay);
      videoElement.removeEventListener("pause", handlePause);
    };
  }, [viewerRef.current]);

  return (
    <div
      className='BroadcastC2CRTCViewer'
      style={{
        ...(getDynamicStyles() as React.CSSProperties),
        ...style,
      }}>
      <div className='BroadcastC2CRTCViewer__video'>
        <video
          muted={muted}
          autoPlay
          playsInline
          controls
          onLoadedData={() => setWebRTCStatus(WebRTCStatus.Playing)}
          ref={viewerRef}
          style={{
            backgroundColor: "transparent",
            width: "100%",
            height: "100%",
            position: "absolute",
            left: "50%",
            top: "50%",
            marginRight: "-50%",
            transform: "translate(-50%, -50%) scaleX(1) scaleY(1) rotate(0deg)",
          }}></video>
      </div>
      <div className='BroadcastC2CRTCViewer__info'>
        <span>{c2c.memberName}</span>
        <span className='BroadcastC2CRTCViewer__flex'></span>
        <span
          onClick={() => setMuted(!muted)}
          className='BroadcastC2CRTCViewer__icon material-icons'>
          {muted ? "volume_off" : "volume_up"}
        </span>
      </div>
    </div>
  );
};

export default inject(
  "broadcastStreamStore",
  "broadcastStore"
)(observer(WebRTCPlayer));
